<template>
  <div class="page">
    <el-form inline>
      <el-form-item label="订单号">
        <el-input size="medium" v-model="searchObj.order_sn"></el-input>
      </el-form-item>
      <el-form-item label="关键字">
        <el-input size="medium" v-model="searchObj.keyword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-checkbox label="未处理" size="medium" v-model="searchObj.type" :true-label="0" :false-label="1"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="searchData">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list">
      <el-table-column label="时间">
        <template slot-scope="scope">
          <span>{{ scope.row.create_at | formatDate}}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单">
        <template slot-scope="scope">
          <div>订单号：{{ scope.row.order_sn }}</div>
          <div>订单名：{{ scope.row.pay_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="价格" prop="price_amount"></el-table-column>
      <el-table-column label="状态" prop="state"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-link :underline="false" type="primary" @click="toOrderDetail(scope.row.order_id)">查看详情</el-link>
          <el-link :underline="false" type="danger" style="margin-left: 10px" @click="delVoucher(scope.row.voucher_id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <div class="pageView">
      <el-pagination layout="prev, pager, next" :page-size="searchObj['per-page']" :current-page.sync="searchObj.page" :total="pageCount" @current-change="pageChangeHandle" />
    </div>
  </div>
</template>

<script>
import { apiOrderAudit, apiDelVoucher } from "@/request/api"
import {formatDate} from "@/utils/theTimeStamp";
export default {
  name: "orderTransfer",
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  data () {
    return {
      pageCount: 0,
      searchObj: {},
      list: []
    }
  },
  created() {
    this.initSearch()
    this.getData()
  },
  methods: {
    initSearch() {
      this.searchObj={
        page: 1,
        'per-page':10,
        type: 0,
        keyword: '',
        order_sn: ''
      }
    },
    getData() {
      apiOrderAudit(this.searchObj).then(res => {
        if (res.code == 200) {
          this.pageCount = res.data.count
          this.list = res.data.list
        }
      })
    },
    delVoucher(voucherId) {
      this.$confirm('确认删除该转账记录？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        apiDelVoucher({voucher_id: voucherId}).then(res => {
          if(res.code == 200) {
            this.searchData()
          }
        })
      })
    },
    toOrderDetail(id){
      this.$router.push({
        path:'/order/orderDetail',
        query:{
          id:id,
          Voucher: true
        }
      })
    },
    searchData() {
      this.searchObj.page = 1
      this.getData()
    },
    pageChangeHandle(){
      this.getData()
    },
  }
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.pageView {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.el-form-item{
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
